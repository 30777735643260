import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../../components/layout';
import Nav from '../../nav.mdx';
export const _frontmatter = {
  "title": "Docs",
  "noNav": true
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Docs`}</h1>
    <Nav mdxType="Nav" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      